<template>
  <v-dialog
    ref="dialog"
    :value="value"
    :persistent="
      options.hasOwnProperty('persistent') ? options['persistent'] : true
    "
    :no-click-animation="
      options.hasOwnProperty('no-click-animation')
        ? options['no-click-animation']
        : true
    "
    v-bind="options"
    @click:outside="$emit('set:value', $refs.dialog.persistent)"
    @keydown="$emit('set:value', $refs.dialog.persistent)"
  >
    <template #activator="slot">
      <slot name="dialog-activator" v-bind="slot"></slot>
    </template>
    <v-card
      color="#011d36"
      style="
        border: 2px solid #1587b2;
        box-shadow: rgb(56 121 146) 0px -1px 20px 0px inset;
      "
    >
      <v-toolbar
        color="#011d36"
        dense
        style="
          box-shadow: rgb(56 121 146) 0px 8px 20px 0px inset;
          border-bottom: 1px solid #113d65;
        "
      >
        <v-toolbar-title
          style="display: flex; align-items: center; color: #24e4f7"
        >
          <span
            style="
              display: inline-block;
              width: 4px;
              height: 22px;
              border-radius: 8px;
              margin-right: 8px;
              background: #24e4f7;
            "
          ></span>
          {{ options.title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('set:value', false)">
          <img src="@/assets/screen/bj/close.png" alt="close" />
        </v-btn>
      </v-toolbar>
      <div class="scrollbar-y" style="max-height: 82vh; padding: 12px">
        <slot></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  //组件注册
  components: {},
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.scrollbar-y {
  overflow-x: hidden;
  overflow-y: auto;
}
.scrollbar-y::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}
.scrollbar-y::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #25517b;
}
.scrollbar-y::-webkit-scrollbar-track {
  border-radius: 5px;
}
</style>
